import React from 'react'

const TableHeader = () => {
  return(
    <thead>
    <tr className='bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Date of Birth</th>
      <th>
        Provincial Health Number
      </th>
      <th></th>
    </tr>
    </thead>
  )
}

export default TableHeader
