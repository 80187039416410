import React, { useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import TableRow from "../admins/tableComponents/tableRow"
import TableHeader from "../admins/tableComponents/tableHeader"
import ExportButton from "../admins/tableComponents/exportButton"

const Table = ({ issues, pdfExportPath, excelExportPath }) => {
  const [issuesState, setIssuesState] = useState(issues.filter((issue) => issue.issue_type !== 'patient_file_upload'))
  const [selectedIssues, setSelectedIssues] = useState([])
  const [itemOffset, setItemOffset] = useState(0)

  const endOffset = useMemo(() => itemOffset + itemsPerPage, [itemOffset, itemsPerPage])
  const currentIssues = useMemo(() => issues.slice(itemOffset, endOffset), [issues, itemOffset, endOffset])
  const pageCount = useMemo(() => Math.ceil(issues.length / itemsPerPage), [issues, itemsPerPage])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % issues.length
    setItemOffset(newOffset)
  }

  const areAllIssuesSelected = () => !!issuesState && issuesState.length === selectedIssues.length
  const isIssueSelected = (issue) => !!selectedIssues.find(currentIssue => currentIssue.id === issue.id)

  const selectIssue = (issue) => {
    if (!isIssueSelected(issue)) {
      setSelectedIssues(prev => [...prev, issue])
    }
  }

  const toggleSelectAll = (currentState) => {
    if (!areAllIssuesSelected()) {
      setSelectedIssues(currentState)
    } else {
      setSelectedIssues([])
    }
  }

  const deselectIssue = (issue) => {
    setSelectedIssues(prev =>
      prev.filter(currentIssue => currentIssue.id !== issue.id)
    )
  }

  return (
    <>
      <div
        className='flex flex-col min-w-0 w-full mb-6 shadow-lg rounded bg-white'>
        <div className="block w-full overflow-x-auto">
          <table className="table">
            <TableHeader areAllSelected={areAllIssuesSelected()} onSelectAll={() => toggleSelectAll(issuesState)} />
            <tbody className="text-gray-600 text-sm font-light">
            {currentIssues.map((issue) =>
              <TableRow
                key={issue.id}
                issue={issue}
                issueState={issuesState}
                setIssueState={setIssuesState}
                selectIssue={selectIssue}
                deselectIssue={deselectIssue}
                isSelected={isIssueSelected(issue)}
              />
            )}
            </tbody>
          </table>
          <div className="w-full flex justify-end items-end">
            <ReactPaginate
              previousLabel={"\uf104"}
              nextLabel={"\uf105"}
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName={"flex flex-row justify-end w-full text-xs text-gray-600 antialiased justify-items-auto mt-2 mb-3 px-6"}
              activeClassName={"px-1 font-bold"}
              pageClassName={"px-1"}
              previousClassName={"px-1 text-xs fas"}
              nextClassName={"px-1 text-xs fas"}
              breakClassName={"px-1"}
            />
          </div>
        </div>
      </div>
      <div className='w-full flex items-end justify-end'>
        <ExportButton issues={selectedIssues} type='pdf' path={pdfExportPath} />
        <ExportButton issues={selectedIssues} type='excel' path={excelExportPath} />
      </div>
    </>
  )
}

const itemsPerPage = 20

export default Table
