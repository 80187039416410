import Logo from "../../../assets/images/consultMD_logo.png";
import ProvinceLabel from "./provinceLabel";
import React from "react";

const LogoAndProvince = () => {
  return (
    <div className='flex flex-col items-center justify-center'>
      <a href='/caregivers/issues?status=outstanding' className='my-4 flex items-center justify-center'>
        <img src={Logo} className='h-7' alt='ConsultMD' />
      </a>
      <ProvinceLabel textSize='text-sm' />
    </div>
  )
}

export default LogoAndProvince
