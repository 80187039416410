import React, { useMemo, useState, useEffect } from 'react'
import FormatPersonalHealthNumber from '../../shared_components/formatPersonalHealthNumber'
import GenderSelect from "../../shared_components/genderSelect"
import Select from "react-select";

const PatientDemographics = ({ previousPatients, draftProfile, isNewProfile }) => {
  const [selectedPatientProfile, setSelectedPatientProfile] = useState({})
  const [firstName, setFirstName] = useState( draftProfile?.first_name || "")
  const [lastName, setLastName] = useState(draftProfile?.last_name || "")
  const [dateOfBirth, setDateOfBirth] = useState(draftProfile?.dob ||'')
  const [gender, setGender] = useState(draftProfile?.gender && {label: draftProfile?.gender, value: draftProfile?.gender} || {})
  const [personalHealthNumber, setPersonalHealthNumber] = useState(draftProfile?.personal_health_number ||'')

  const patientProfiles = useMemo(() => (
    previousPatients?.map((profile) => (
      {
        label: `${profile.name} | dob: ${profile.dob} `,
        value: profile.id,
        firstName: profile.first_name,
        lastName: profile.last_name,
        dob: profile.dob,
        personalHealthNumber: profile.personal_health_number,
        gender: profile.gender
      }
    ))
  ), [previousPatients])

  const handleClearForm = () => {
    setSelectedPatientProfile({firstName: '', lastName: '', dob: '', personalHealthNumber: '', gender: '', id: null})
  }

  useEffect(() => {
    setFirstName(selectedPatientProfile?.firstName)
    setLastName(selectedPatientProfile?.lastName)
    setDateOfBirth(selectedPatientProfile?.dob)
    setGender({ label: selectedPatientProfile?.gender, value: selectedPatientProfile?.gender })
    setPersonalHealthNumber(selectedPatientProfile?.personalHealthNumber)
  }, [selectedPatientProfile])

  const firstNameAttributeValue = useMemo(() => isNewProfile ? `patient_profile[first_name]` : `issue[patient_profile_attributes][first_name]`,[isNewProfile])
  const lastNameAttributeValue = useMemo(() => isNewProfile ? `patient_profile[last_name]` :`issue[patient_profile_attributes][last_name]`,[isNewProfile])
  const dobAttributeValue = useMemo(() => isNewProfile ? `patient_profile[dob]` :`issue[patient_profile_attributes][dob]`,[isNewProfile])
  const genderAttributeValue = useMemo(() => isNewProfile ? `patient_profile[gender]` :`issue[patient_profile_attributes][gender]`,[isNewProfile])
  const personalHealthNumberAttributeValue = useMemo(() => isNewProfile ? `patient_profile[personal_health_number]` :'issue[patient_profile_attributes][personal_health_number]',[isNewProfile])

  return (
    <div
      className='flex flex-col xl:flex-row items-center gap-x-2 justify-center w-full overflow-x-auto p-1 md:p-8 bg-slate-100'>
      {!isNewProfile && <DisplayPreviousPatients patientProfiles={patientProfiles} setSelectedPatientProfile={setSelectedPatientProfile} selectedPatientProfile={selectedPatientProfile}  />}
      <div className='form-group'>
        <label>First Name<span className="text-red-700">*</span></label>
        <input
          type='text'
          placeholder='Jane'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <input type='hidden'
               name={firstNameAttributeValue}
               value={firstName} />
        <input type='hidden'
               name={`issue[patient_profile_attributes][id]`}
               value={selectedPatientProfile?.value} />
      </div>
      <div className='form-group'>
        <label>Last Name<span className="text-red-700">*</span></label>
        <input
          type='text'
          placeholder='Doe'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <input type='hidden'
               name={lastNameAttributeValue}
               value={lastName} />
      </div>
      <div className='form-group'>
        <label>Date of Birth<span className="text-red-700">*</span></label>
        <input type='date' value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
        <input type='hidden'
               name={dobAttributeValue}
               value={dateOfBirth} />
      </div>
      <div className='form-group'>
        <GenderSelect gender={gender} setGender={setGender} genderAttributeValue={genderAttributeValue} />
      </div>
      <div className='form-group'>
        <label>Personal Health Number<span className="text-red-700">*</span></label>
        <FormatPersonalHealthNumber personalHealthNumber={personalHealthNumber}
                                    setPersonalHealthNumber={setPersonalHealthNumber}
                                    personalHealthNumberAttributeValue={personalHealthNumberAttributeValue}
        />
      </div>
      <span onClick={handleClearForm} className='text-xs underline cursor-pointer'>Reset form</span>
    </div>
  )
}


const customStyles = {
  control: base => ({
    ...base,
    height: 46,
    minHeight: 46,
    border: "1px solid rgb(203 213 225)",
  })
};

export default PatientDemographics

const DisplayPreviousPatients = ({ patientProfiles, setSelectedPatientProfile, selectedPatientProfile }) => {
  return (
    <div className='form-group'>
      <label>Previous Patients</label>
      <Select
        options={patientProfiles || []}
        onChange={setSelectedPatientProfile}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        value={selectedPatientProfile}
      />
    </div>
  )
}
