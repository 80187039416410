import React, {useState} from 'react'
import QuestionSelect from "./questionSelect";
import AnatomyInput from './anatomyInput';
import DurationSelects from './durationSelects'

const IssueQuestion = ({ i, question, onSelect, draftValue }) => {
  const [draftResponse, setDraftResponse] = useState(determineValues(draftValue, question))
  const renderFormField = () => {
    switch (question.type) {
      case 'text':
        return (<>
            {<>
              <textarea name={`issue[responses_attributes][${i}][value]`} required={question.required}
                        value={draftResponse?.value} onChange={(e) => setDraftResponse(e.target.value)}></textarea>
              <input type='hidden' name={`issue[responses_attributes][${i}][id]`} value={draftResponse?.id} />
            </>}
          </>
        )

      case 'string':
        return <input type='text' name={`issue[responses_attributes][${i}][value]`} required={question.required} value={draftResponse?.value}  />
      case 'select':
        return (<>
            {<>
              <QuestionSelect question={question} name={`issue[responses_attributes][${i}][value]`}
                              value={draftResponse?.value} onChange={setDraftResponse}
                              required={question.required} />
              <input type='hidden' name={`issue[responses_attributes][${i}][id]`} value={draftResponse?.id} />
            </>}
          </>
        )

      case 'file':
        return (
          <div className='pb-6'>
            <input type='file' name={`issue[responses_attributes][${i}][files][]`} multiple
                   required={question.required} onChange={onSelect} />
            <label className='pt-4'>Image Description</label>
            <input type='text' name={`issue[responses_attributes][${i}][value]`} required={question.required} />
          </div>
        )
      case 'diagram':
        return (<>
            {<>
              <AnatomyInput value={draftResponse?.value} name={`issue[responses_attributes][${i}][value]`} />
              <input type='hidden' name={`issue[responses_attributes][${i}][id]`} value={draftResponse?.id} />
            </>}
          </>
        )
      case 'two_selects':
        return (<>
            {<>
              <DurationSelects value={draftResponse?.value?.split(' ')} question={question}
                               name={`issue[responses_attributes][${i}][value]`} required={question.required} />
              <input type='hidden' name={`issue[responses_attributes][${i}][id]`} value={draftResponse?.id} />
            </>}
          </>
        )
    }
  }

  return <div className='form-group'>
    <div className='flex items-center gap-x-1 text-red-700'>
      <label>{question.description}</label>
      {question.required && <span>*</span>}
    </div>
    <div className='flex flex-col'>
      <input type='hidden' name={`issue[responses_attributes][${i}][question_key]`} defaultValue={question.key} />
      <input type='hidden' name={`issue[responses_attributes][${i}][question_type]`} defaultValue={question.type} />
      {renderFormField()}
    </div>
  </div>
}

export default IssueQuestion

const determineValues = (draftValue, question) => {
  if(draftValue) {
    return draftValue
  }
  if(question.type === 'select' || question.type === 'two_selects') {
    return {value: 'Pending Assessment', question_key: question.key, question_type: question.type}
  }
  if(question.type === 'text') {
    return {value: 'THIS IS A PLACEHOLDER. Please Update values before sending consult.', question_key: question.key, question_type: question.type}
  }
  else {
    return ''
  }
}
