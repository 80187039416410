import React from 'react';
import { DisplayValue } from "../../../../utilities/displayValue";

const TableRow = ({ profile }) => {
  return (
    <tr className='cursor-pointer'>
      <td>{DisplayValue(profile.first_name)}</td>
      <td>{DisplayValue(profile.last_name)}</td>
      <td>{DisplayValue(profile.dob)}</td>
      <td>{DisplayValue(profile.personal_health_number)}</td>
      <td className='flex items-center gap-x-8'>
        <a href={`/caregivers/consults/issues/new?patient_profile=${profile.id}`} className='small-btn btn-orange'>+ Create Consult</a>
        <a href={`/caregivers/patient_profiles/${profile.id}`}
           className='small-btn btn-teal'>View</a>
        <a href={`/caregivers/patient_profiles/${profile.id}/edit`} className='small-btn btn-orange'
           className='small-btn btn-teal'>Edit</a>
      </td>
    </tr>
  )
}

export default TableRow
