import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

const GenderSelect = ({ gender, setGender, genderAttributeValue }) => {

  const genderOptions = useMemo( () => [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
    { value: 'Undisclosed', label: 'Undisclosed' },
  ],[])


  return (
    <div className='form-group'>
      <label>Gender Select<span className="text-red-700">*</span></label>
      <Select
        options={genderOptions || []}
        onChange={setGender}
        value={gender && genderOptions.find(function (option) {
          return option.value === gender.value
        })}
        styles={customStyles}
        required={true}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
      />
      <input type='hidden'
             name={genderAttributeValue}
             value={gender?.value} />
    </div>
  )
}

const customStyles = {
  control: base => ({
    ...base,
    height: 46,
    minHeight: 46,
    border: "1px solid rgb(203 213 225)",
  })
};

export default GenderSelect
