import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { highlightCurrentPath } from "../../utilities/pathHelpers"

const NavLink = ({ icon, text, url, iconColor, dataMethod = 'get' }) => {
  return (
    <li className={`items-center`}>
      <div className={`flex flex-row w-full items-center justify-between`}>
        <a href={url} className={highlightCurrentPath(url)} data-method={dataMethod}>
          <span className="flex items-center pl-2">
            <FontAwesomeIcon icon={icon} className={`mr-2 text-sm w-16 text-center ${iconColor}`} />
            {icon && <p className="w-full text-start">{text}</p>}
          </span>
        </a>
      </div>
    </li>
  )
}

export default NavLink
