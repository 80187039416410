import React, { useMemo, useState } from 'react'
import TableHeader from "././tableComponents/tableHeader"
import TableRow from "././tableComponents/tableRow"
import ReactPaginate from "react-paginate";

const Table = ({patientProfiles}) => {
  const [itemOffset, setItemOffset] = useState(0)

  const endOffset = useMemo(() => itemOffset + itemsPerPage, [itemOffset, itemsPerPage])
  const currentProfiles = useMemo(() => patientProfiles.slice(itemOffset, endOffset), [patientProfiles, itemOffset, endOffset])
  const pageCount = useMemo(() => Math.ceil(patientProfiles.length / itemsPerPage), [patientProfiles, itemsPerPage])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % patientProfiles.length
    setItemOffset(newOffset)
  }

  return (
    <div className='flex flex-col min-w-0 w-full mb-6 shadow-lg rounded bg-white'>
      <div className="block w-full overflow-x-auto">
        <table className='table'>
          <TableHeader />
          <tbody className="text-gray-600 text-sm font-light">
          {currentProfiles.map((profile) => {
            return <TableRow key={profile.id} profile={profile} />
          })}
          </tbody>
        </table>
        <div className="w-full flex justify-end items-end">
          <ReactPaginate
            previousLabel={"\uf104"}
            nextLabel={"\uf105"}
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName={"flex flex-row justify-end w-full text-xs text-gray-600 antialiased justify-items-auto mt-2 mb-3 px-6"}
            activeClassName={"px-1 font-bold"}
            pageClassName={"px-1"}
            previousClassName={"px-1 text-xs fas"}
            nextClassName={"px-1 text-xs fas"}
            breakClassName={"px-1"}
          />
        </div>
      </div>
    </div>
  )
}

const itemsPerPage = 10

export default Table
